import React from 'react';
import { Navigate } from 'react-router-dom';

// Dynamisch laden van collectie-componenten
const Beat40 = React.lazy(() => import('../pages/collecties/Beat40'));
const Beat45 = React.lazy(() => import('../pages/collecties/Beat45'));
const Nuance515 = React.lazy(() => import('../pages/collecties/Nuance515'));
const Spiegels = React.lazy(() => import('../pages/collecties/Spiegels'));

const Linea = React.lazy(() => import('../pages/collecties/exclusief/Linea'));
const Epoque = React.lazy(() => import('../pages/collecties/exclusief/Epoque'));
const Morrison = React.lazy(() => import('../pages/collecties/exclusief/Morrison'));
const Tresore = React.lazy(() => import('../pages/collecties/exclusief/Tresore'));
const Ligne = React.lazy(() => import('../pages/collecties/exclusief/Ligne'));
const Modena = React.lazy(() => import('../pages/collecties/exclusief/Modena'));

const Sjithouse = React.lazy(() => import('../pages/collecties/Sjithouse'));
const Thor = React.lazy(() => import('../pages/collecties/Thor'));
const Type = React.lazy(() => import('../pages/collecties/Type'));

const Ontwikkel = React.lazy(() => import('../components/Ontwikkel')); // Placeholder voor niet-gereed componenten

const CollectieRoutes = [
  { path: '/collectie/7158-beat-45', element: <Navigate to="/collecties/beat40" replace /> },
  { path: '/collecties/sjithouse', element: <Sjithouse />, theme: 'light', header: 'herotrans' },
  { path: '/collecties/thor', element: <Thor />, theme: 'light', header: 'herotrans' },
  { path: '/collecties/type', element: <Type />, theme: 'light', header: 'herotrans' },

  { path: '/collecties/beat40', element: <Beat40 />, theme: 'light', header: 'herotrans' },
  { path: '/collecties/beat45', element: <Beat45 />, theme: 'light', header: 'herotrans' },
  { path: '/collecties/nuance515', element: <Nuance515 />, theme: 'light', header: 'herotrans' },

  { path: '/collecties/spiegels', element: <Spiegels />, theme: 'light' },

  { path: '/collecties/exclusief/linea', element: <Linea />, theme: 'dark' },
  { path: '/collecties/exclusief/epoque', element: <Epoque />, theme: 'dark' },
  { path: '/collecties/exclusief/morrison', element: <Morrison />, theme: 'dark' },
  { path: '/collecties/exclusief/tresore', element: <Tresore />, theme: 'dark' },
  { path: '/collecties/exclusief/ligne', element: <Ligne />, theme: 'dark' },
  { path: '/collecties/exclusief/modena', element: <Modena />, theme: 'dark' },
];

export default CollectieRoutes;
