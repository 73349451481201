import React from 'react';
import './InstructionComponentVideo.css';

const InstructionComponentVideo = ({ title, subtext, videoLink }) => {
  // Controleer of het een YouTube-link is
  const isYouTubeVideo = videoLink.includes('youtube.com') || videoLink.includes('youtu.be');

  return (
    <div className="video-container-instruction">
      <div className="video-container-content">
        <h2>{title}</h2>
        <p>{subtext}</p>
        {Array.isArray(subtext) && (
          <ul>
            {subtext.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}

        <div className="video-wrapper">
          {isYouTubeVideo ? (
            // Gebruik iframe voor YouTube-video's
            <iframe
              width="100%"
              height="480"
              src={videoLink}
              title={title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          ) : (
            // Gebruik video-tag voor lokale video's
            <video controls width="100%" height="480">
              <source src={videoLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstructionComponentVideo;
