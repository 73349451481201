import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ScrollSmoother } from 'gsap/ScrollSmoother';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const smoother = ScrollSmoother.get();

    const timeoutId = setTimeout(() => {
      // Check if GSAP ScrollSmoother exists and use it to scroll to the top
      if (smoother) {
        smoother.scrollTop(0, true); // Scroll smoothly to the top using GSAP
      } else {
        window.scrollTo(0, 0); // Fallback for non-GSAP scroll
      }
    }, 50); // 50ms delay

    return () => {
      clearTimeout(timeoutId); // Clean up timeout
    };
  }, [pathname]);

  return null;
}

export default ScrollToTop;
