import React, { useState, useEffect, useRef } from 'react';
import './Cookie.css';

const Cookie = () => {
  const [adsEnabled, setAdsEnabled] = useState(true);
  const [isVisible, setIsVisible] = useState(false); // Start niet zichtbaar
  const cookieRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const analyticsCookie = getCookie('analytics');
      const adsCookie = getCookie('ads');

      if (analyticsCookie === null || adsCookie === null) {
        setIsVisible(true);
      }
    }, 3000); // Vertraag de check met 3 seconden

    return () => clearTimeout(timeout); // Opruimen van timeout wanneer component unmount
  }, []);

  const handleAcceptAll = () => {
    setAdsEnabled(true);
    saveCookies(true, true);
    setIsVisible(false);
  };

  const handleConfirmChoice = () => {
    saveCookies(true, adsEnabled);
    setIsVisible(false);
  };

  const saveCookies = (analytics, ads) => {
    document.cookie = `analytics=${analytics}; path=/; max-age=${60 * 60 * 24 * 365}`;
    document.cookie = `ads=${ads}; path=/; max-age=${60 * 60 * 24 * 365}`;
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  if (!isVisible) return null;

  return (
    <div className='cookie-overlay'>
      <div className="cookie-container">
        <div className="cookie-image" ref={cookieRef}>
          <img src="/assets/img/application/cookie.webp" alt="Cookie" />
        </div>
        <h2>Mogen we cookies opslaan?</h2>
        <div className="cookie-options">
          <div className="cookie-option">
            <div>
              <h2 className="option-title">Analytisch</h2><p>(Altijd aan)</p>
              <p className="option-description">Deze cookies helpen ons inzicht te krijgen in het gebruik van onze website.</p>
            </div>
            <label className="switch disabled">
              <input type="checkbox" checked={true} readOnly disabled />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="cookie-option">
            <div>
              <span className="option-title">Advertentiedoeleinden</span><p>(Optioneel)</p>
              <p className="option-description">Deze cookies worden gebruikt om advertenties te personaliseren.</p>
            </div>
            <label className="switch">
              <input
                type="checkbox"
                checked={adsEnabled}
                onChange={() => setAdsEnabled(!adsEnabled)}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <div className="cookie-buttons">
          <button onClick={handleConfirmChoice}>Keuze bevestigen</button>
          <button className="confirm" onClick={handleAcceptAll}>Accepteer alles</button>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
