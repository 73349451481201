import React from 'react';
import PropTypes from 'prop-types';
import './Media.css';

const MediaComponent = ({ src, type, size, alt, className }) => {
  // Dynamische grootte bepalen op basis van de `size`-prop
  const dimensions = {
    small: { width: '200px', height: '150px' },
    medium: { width: '400px', height: '300px' },
    large: { width: '800px', height: '600px' },
  };

  const { width, height } = dimensions[size] || dimensions.medium;

  // Renderen van verschillende media
  return (
    <div className={`media-component ${className || ''}`}>
      {type === 'image' && (
        <img src={src} alt={alt || 'Media'} style={{ width, height }} />
      )}
      {type === 'video' && (
        <iframe
          src={src}
          width={width}
          height={height}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded Video"
        ></iframe>
      )}
      {!['image', 'video'].includes(type) && (
        <p>Unsupported media type: {type}</p>
      )}
    </div>
  );
};

// Default props
MediaComponent.defaultProps = {
  size: 'medium',
  type: 'image',
  alt: '',
};

// Prop types
MediaComponent.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['image', 'video']).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default MediaComponent;
