import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa'; // Import icons from React Icons
import './FeedbackBanner.css';

const FeedbackBanner = () => {
  const [submitted, setSubmitted] = useState(false);
  const [negativeFeedback, setNegativeFeedback] = useState(false);
  const bannerRef = useRef(null); // Create a ref to target the feedback banner
  const textRef = useRef(null); // Create a ref for the text section

  // Dynamically extract the slug from the current URL
  const getQuestionSlug = () => {
    const pathArray = window.location.pathname.split('/');
    return pathArray[pathArray.length - 1] || ''; // Get the last segment of the URL or an empty string if unavailable
  };

  const questionSlug = getQuestionSlug(); // Extract the questionSlug from URL


  // Check localStorage to see if feedback has already been given
  useEffect(() => {
    const feedbackData = JSON.parse(localStorage.getItem('feedback')) || {};
    if (feedbackData[questionSlug]) {
      setSubmitted(true);
      console.log('Feedback already submitted for this question');
    }
  }, [questionSlug]);

  const submitFeedback = async (feedbackType) => {
    const location = window.location.href;

    // Always show feedback UI, regardless of the outcome of the request
    setSubmitted(true);
    if (feedbackType === 'bad') {
      setNegativeFeedback(true);
    }

    try {
      console.log('Submitting feedback:', feedbackType);

      const response = await axios.post('https://api.thebalux.nl/api/feedback', {
        location,
        question_slug: questionSlug || '', // Use extracted questionSlug or empty string
        feedback: feedbackType,
      });

      console.log('Response from backend:', response); // Log the response from backend

      // Store the feedback in localStorage to prevent multiple submissions
      const feedbackData = JSON.parse(localStorage.getItem('feedback')) || {};
      feedbackData[questionSlug] = true;
      localStorage.setItem('feedback', JSON.stringify(feedbackData));
    } catch (error) {
      console.error('Error submitting feedback:', error.response ? error.response.data : error);
      // Even if the request fails, don't let the user know (keep the "thank you" message)
    }
  };

  return (
    <div className="feedback-banner" ref={bannerRef}> {/* Use the ref here */}
      <div ref={textRef}> {/* Wrap the text section with this ref */}
        {!submitted ? (
          <>
            <p>Heeft deze pagina u geholpen?</p>
            <div className="feedback-buttons">
              <button onClick={() => submitFeedback('good')} className="thumb-button">
                <FaThumbsUp />
              </button>
              <button onClick={() => submitFeedback('bad')} className="thumb-button">
                <FaThumbsDown />
              </button>
            </div>
          </>
        ) : (
          <div>
            {negativeFeedback ? (
              <>
                <p>Bedankt voor uw feedback, kunnen we u op een andere manier wél op weg helpen?</p>
                <Link to="/service" className="service-link">
                  <button>Ga naar onze servicepagina</button>
                </Link>
              </>
            ) : (
              <p>Bedankt voor uw feedback!</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackBanner;
