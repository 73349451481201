import React, { useEffect, useRef } from 'react';
import './ImageParallax.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ImageParallax = ({ 
  imageLeft = '/assets/img/exclusief/col/modena/webp/20240731-Modena160Scene-rrpf-cam2-01.webp', 
  imageRight = '/assets/img/exclusief/col/modena/webp/Modena160Scene-rrpf-cam8-01_il.webp', 
  title = 'Nederlandse vakmanschap', 
  description = 'De kwaliteit die uit Nederland komt, staat wereldwijd bekend om zijn duurzaamheid, precisie en vakmanschap. Van de eerste ontwerpschetsen tot het laatste product, elk detail wordt met zorg en aandacht uitgevoerd. Dit product, met trots gemaakt in Nederland, weerspiegelt deze waarden en biedt u de zekerheid van een product dat is gemaakt om lang mee te gaan.', 
  link = '/over-ons',
  linkText = 'Lees meer over Nederlandse kwaliteit'

}) => {

  const leftSectionRef = useRef(null);
  const rightSectionRef = useRef(null);

  useEffect(() => {
    const leftSection = leftSectionRef.current;
    const rightSection = rightSectionRef.current;

    if (leftSection && rightSection) {
      const leftAnimation = gsap.to(leftSection, {
        y: '-100px',
        ease: 'none',
        scrollTrigger: {
          trigger: leftSection,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          toggleActions: 'play reverse play reverse',
        },
      });

      const rightAnimation = gsap.to(rightSection, {
        y: '300px',
        ease: 'none',
        scrollTrigger: {
          trigger: rightSection,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          toggleActions: 'play reverse play reverse',
        },
      });

      return () => {
        leftAnimation.kill();
        rightAnimation.kill();
      };
    }
  }, []);

  return (
    <section className="extrta-info-bricks-container">
      <div className="extrta-info-bricks-left" ref={leftSectionRef}>
        <img 
          src={imageLeft} 
          alt="LinkerAfbeelding" 
          className="extrta-info-bricks-image" 
        />
      </div>
      <div className="extrta-info-bricks-right" ref={rightSectionRef}>
        <img 
          src={imageRight} 
          alt="RechterAfbeelding" 
          className="extrta-info-bricks-image" 
        />
        <div className="extrta-info-bricks-text">
          <h2>{title}</h2>
          <p>{description}</p>
          <a href={link} className="extrta-info-bricks-link">
            <button className="button">{linkText}</button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ImageParallax;
