import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faPinterestP, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <h4>Sitemap</h4>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/inspiratie">Inspiratie</Link></li>
            <li><Link to="/collecties">Collecties</Link></li>
            <li><Link to="/verkooppunten">Uw verkooppunt</Link></li>
            <li><Link to="/showroom">Showroom</Link></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>Snel naar</h4>
          <ul>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/downloads">Downloads</Link></li>
            <li><Link to="/over-ons">Over ons</Link></li>
            <li><Link to="/privacy">Privacyverklaring</Link></li>
            <li><Link to="/duurzaamheid">Duurzaamheid</Link></li>
            <li><Link to="/werken-bij">Vacatures</Link></li>
            <li><a target='blank' href="https://imagebank.thebalux.nl">Imagebank</a></li>

          </ul>
        </div>

        <div className="footer-column">
          <h4>Socials</h4>
          <div className="social-icons">
            <a href="https://www.facebook.com/ThebaluxBadkamermeubelen" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.pinterest.com/badkamermeubels" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faPinterestP} />
            </a>
            <a href="https://www.youtube.com/thebaluxbadmeubelen" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://www.instagram.com/thebaluxbadkamermeubelen" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>

        <div className="footer-column">
          <h4>Thebalux</h4>
          <p>Hoge Balver 19<br/>7207BR Zutphen</p>
          <p>Tel. +31 (0)575 - 588333</p>
          <br />
          <Link to='/contact'>
            <button>Ga naar contact</button>
          </Link>     
        </div>

        <div className="footer-column">
          <h4>Hier zijn wij trots op!</h4>
          <div className="certificates">
            <img src="/assets/img/application/greatplacetowork.webp" alt="Great Place to Work"  loading="lazy"/>
            <img src="/assets/img/application/EcoVadis.webp" alt="Great Place to Work" loading="lazy" />

          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 1995 - 2024 Thebalux</p>
      </div>
    </footer>
  );
};

export default Footer;
