import React from 'react';
import useNieuwsBrief from './useNieuwsBrief'; // Import shared logic
import './NieuwsBriefPopup.css';

const NieuwsBriefPopup = () => {
  const { email, setEmail, type, setType, language, setLanguage, handleInschrijven, sluitPopup, toonPopup, bevestigd, error } = useNieuwsBrief();

  const handleBuitenKlik = (e) => {
    if (e.target.classList.contains('popup-container-animatie')) {
      sluitPopup(false);
    }
  };

  return (
    toonPopup && (
      <div className="popup-container-animatie" onClick={handleBuitenKlik}>
        <div className="popup-inhoud">
          {!bevestigd ? (
            <>
              <h2>Schrijf je in voor onze nieuwsbrief</h2>
              <p>Blijf op de hoogte van al het nieuws.</p>
              <input 
                type="email" 
                placeholder="Vul je e-mailadres in" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
              />
              <div className="opties">
               
              </div>
              <div className="opties">
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="Nederlands">Nederlands</option>
                  <option value="Engels">Engels</option>
                  <option value="Duits">Duits</option>
                </select>

                <button
                  className={type === 'Particulier' ? 'active' : ''}
                  onClick={() => setType('Particulier')}
                >
                  Particulier
                </button>
                <button
                  className={type === 'Bedrijf' ? 'active' : ''}
                  onClick={() => setType('Bedrijf')}
                >
                  Bedrijf
                </button>
              </div>
              <div className="popup-knoppen">
                <button onClick={() => sluitPopup(true)}>Nee, bedankt</button>
                <button onClick={handleInschrijven}>Inschrijven</button>
              </div>
              <br></br>
              {error && <p className="error">{error}</p>} {/* Show error message in red */}

            </>
          ) : (
            <div className="bevestiging">
             <h1>You've got mail!</h1>
              <p>Bedankt voor je inschrijving!</p>
              <button onClick={() => sluitPopup(false)}>Sluiten</button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default NieuwsBriefPopup;
