import { Navigate } from 'react-router-dom';

const CollectieRoutes = [
  { path: '/collectie', element: <Navigate to="/collecties" replace /> },

  { path: '/home', element: <Navigate to="/" replace /> },


  { path: '/collectie/7157-beat-40', element: <Navigate to="/collecties/beat40" replace /> },
  { path: '/collectie/7158-beat-45', element: <Navigate to="/collecties/beat40" replace /> },
  { path: '/showrooms', element: <Navigate to="/verkooppunten" replace /> },

  { path: '/collectie/kleurenoverzicht', element: <Navigate to="/kleuren" replace /> },
  { path: '/blog/886-kleuren', element: <Navigate to="/kleuren" replace /> },
  { path: '/blog/855-nieuwe-kleuren-thebalux-badmeubelprogramma', element: <Navigate to="/kleuren" replace /> },

  { path: '/collectie/7159-nuance-515', element: <Navigate to="/collecties/nuance515" replace /> },
  { path: '/collectie/7160-sjithouse-furniture', element: <Navigate to="/collecties/sjithouse" replace /> },
  
  { path: '/producten/31-spiegels', element: <Navigate to="/collecties/spiegels" replace /> },

  { path: '/collectie/spiegelboek', element: <Navigate to="/collecties/spiegels" replace /> },

  { path: '/bestanden/22553/sjithouse_montagehanleiding6630.pdf', element: <Navigate to="/montage" replace /> },

  {
  path: '/bestanden/22571/informatie montage hoeksteunen7150.pdf',
  element: <Navigate to="/assets/bestanden/montage/Informatie_montage_hoeksteunen.pdf" replace />,
  },


  { path: '/veelgestelde-vragen/19251-wat-zijn-de-voordelen-van-een-mineraalmarmer-wastafel-keramische-wastafel-glazen-wastafel-of-wastafel-van-keratop', element: <Navigate to="/veelgestelde-vragen" replace /> },
  { path: '/veelgestelde-vragen/19239-instellenafstellen-van-push-open-laden', element: <Navigate to="/veelgestelde-vragen" replace /> },
  { path: '/veelgestelde-vragen/19240-hoe-verwijder-ik-de-lade-uit-het-badkamermeubel', element: <Navigate to="/veelgestelde-vragen" replace /> },
  { path: '/veelgestelde-vragen/19247-hoe-stel-ik-de-klok-in-van-mijn-time-of-watch-led-spiegel', element: <Navigate to="/veelgestelde-vragen" replace /> },

  { path: '/producten/31-spiegels', element: <Navigate to="/" replace /> },

];

export default CollectieRoutes;
