import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './FullWidthImage.css';

gsap.registerPlugin(ScrollTrigger);

const FullWidthImage = ({
  imageSrc,
  title,
  description,
  buttonText,
  buttonLink,
  placement = 'left',
  height,
  padding = '0',
  overlay = '0',
}) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top 75%',
        end: 'bottom 25%',
        scrub: 0.1, // Smooth scrubbing effect
        toggleActions: 'play none none reverse',
      },
    });

    timeline
      .fromTo(
        textRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1 } // Faster opacity animation
      )
      .fromTo(
        textRef.current,
        { y: -225 },
        { y: 250, duration: 3 }, // Longer duration for movement
        '<' // Start simultaneously with the opacity animation
      );

    return () => {
      if (timeline.scrollTrigger) timeline.scrollTrigger.kill();
      timeline.kill();
    };
  }, [placement]);

  return (
    <div
      className="fullwidthcollect"
      style={{
        height: height || '150vh',
        padding,
        '--overlay-opacity': overlay, // Set the overlay opacity dynamically
      }}
      ref={containerRef}
    >
      <img src={imageSrc} alt={title} />
      <div
        className={`textfullwidth ${placement === 'rechts' ? 'right-align' : ''}`}
        ref={textRef}
      >
        <h1>{title}</h1>
        <p>{description}</p>
        {buttonText && buttonLink && (
          <a href={buttonLink} className="fullwidth-button">
            <button>{buttonText}</button>
          </a>
        )}
      </div>
    </div>
  );
};

export default FullWidthImage;
