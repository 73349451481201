// Scala.jsx
import React from 'react';
import Spacer from '../../../components/Spacer';
import { Link } from 'react-router-dom';
import FeedbackBanner from '../../../components/faq/FeedbackBanner';          
import InstructionComponentVideo from '../../../components/montage/InstructionComponentVideo';
import Note from '../../../components/Note';
import './XP.css';
import MediaComponent from '../../../components/algemeen/MediaComponent';

const XP = () => {
  return (


    <div>
    
      <Spacer height='90px'/>


      <div className='backlinks'>
            <Link to='/montage/ladesystemen'><button>Terug naar ladesystemen</button></Link>
      </div>

      <Spacer height='4vh'/>

        <Note 
        title='Mepla XP'
        sub= "Te herkennen aan lichte welving in zijkant van de lade en aanwezigheid van afdekkapje."
        />

        <div class="text-lijstje">
        <div>
            <strong>Schroef 1 – Demonteren ladefront</strong>
            <p>
            Let op, controleer eerst of er een metalen hoek is geplaatst onder de lade. Het hoekje zit vast aan de onderkant van de bodemplaat en aan de binnenkant van het front. Zorg ervoor dat het hoekje is verwijderd voordat het front gedemonteerd gaat worden. De hoek dient wel teruggeplaatst te worden. Het verstevigd de constructie van de lade. Het advies om het hoekje na het stellen niet op dezelfde plek terug te plaatsen, dit zou er voor kunnen zorgen dat het front na verloop van tijd weer teruggetrokken wordt naar de oorspronkelijke en wellicht onjuiste afstelling.
            Demonteren: Draai de pal een kwartslag. Het ladefront komt los. Ditzelfde is aan de linkerkant te doen.
            </p>
        </div>
        <div>
            <strong>Schroef 2 – Horizontaal stellen ladefront</strong>
            <p>
            Stelmogelijkheid enkel aan rechterkant te vinden. Ook hier is het belangrijk om de hoekverbinding onder de lade eerst los te halen. Anders is het front niet horizontaal te stellen. Na het stellen de hoek op een andere plaats weer monteren, anders trekt het front weer terug naar de originele plaats.
            Stellen: draai de schroef aan en het front gaat naar links, draai de schroef los en het front gaat naar rechts.
            </p>
        </div>
        <div>
            <strong>Schroef 3 – Verticaal stellen ladefront</strong>
            <p>
            Draai eerst de vergrendeling (schroef 4) los. Met schroef 3 is de hoogte te stellen. Linksom is omhoog, rechtsom is omlaag. Zodra het front op de gewenste hoogte is kan de plaats vastgezet worden door schroef 4 vast te draaien.
            </p>
        </div>
        <div>
            <strong>Schroef 4 – Vergrendeling verticaal stellen ladefront</strong>
            <p>
            Te gebruiken om gewenste hoogte van het ladefront vast te zetten.
            </p>
        </div>
        </div>

        {<MediaComponent
        src="/assets/img/faq/1-2.jpg"
        type="image"
        size="large"
        alt="-"
        fromAssets={true}
      /> }



        <InstructionComponentVideo 
        title="Verwijderen lade"
        subtext={["Schuif de geleiders uit", "Pak het front vast en geef een kleine ruk omhoog om de lade te verwijderen"]}
        videoLink="https://www.youtube.com/embed/f38p5B_Vg9E"
        />

      <Spacer height='10vh'/>

      <FeedbackBanner />

    </div>
  );
};

export default XP;
