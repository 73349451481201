import { useState, useEffect } from 'react';

const useNieuwsBrief = () => {
  const [email, setEmail] = useState('');
  const [type, setType] = useState('Particulier');
  const [language, setLanguage] = useState('Nederlands');
  const [toonPopup, setToonPopup] = useState(false);
  const [bevestigd, setBevestigd] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const popupVerwijderd = localStorage.getItem('popupVerwijderd');
    const popupWeggeklikt = localStorage.getItem('popupWeggeklikt');
    const popupBevestigd = localStorage.getItem('popupBevestigd');
    const huidigeTijd = new Date().getTime();

    // Als popup is bevestigd binnen 90 dagen, dan tonen we het niet
    if (popupBevestigd && huidigeTijd < new Date(popupBevestigd).getTime()) {
      return;
    }

    if (popupWeggeklikt && huidigeTijd < new Date(popupWeggeklikt).getTime()) {
      return;
    }

    if (popupVerwijderd && huidigeTijd < new Date(popupVerwijderd).getTime()) {
      return;
    }

    const timer = setTimeout(() => {
      setToonPopup(true);
    }, 35000); // Delay for showing popup

    return () => clearTimeout(timer);
  }, []);

  const sluitPopup = (isNeeBedankt) => {
    setToonPopup(false);
    const huidigeTijd = new Date().getTime();
    if (isNeeBedankt) {
      const in30Dagen = new Date(huidigeTijd + 30 * 24 * 60 * 60 * 1000); // 30 days in ms
      localStorage.setItem('popupVerwijderd', in30Dagen);
    } else {
      const in30Minuten = new Date(huidigeTijd + 30 * 60 * 1000); // 30 minutes in ms
      localStorage.setItem('popupWeggeklikt', in30Minuten);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInschrijven = async () => {
    if (!email) {
      setError('Vul alstublieft een e-mailadres in.');
      return;
    }
    if (!validateEmail(email)) {
      setError('Vul een geldig e-mailadres in.');
      return;
    }

    const submissionData = {
      email,
      type: type || 'Not specified',
      language: language || 'Not specified',
    };

    try {
      const response = await fetch('https://api.thebalux.nl/api/nieuwsbrief', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });

      const data = await response.json();
      if (response.ok) {
        setBevestigd(true);
        setError('');
        
        // Opslaan van bevestiging voor 90 dagen
        const in90Dagen = new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000);
        localStorage.setItem('popupBevestigd', in90Dagen);
      } else if (data.errorCode === 'EMAIL_ALREADY_EXISTS') {
        setError('U bent al ingeschreven voor onze nieuwsbrief!');
      } else {
        console.error('Er ging iets mis:', data.message);
        setError('Er ging iets mis. Probeer het later opnieuw.');
      }
    } catch (error) {
      console.error('Fout bij het versturen van de gegevens:', error);
      setError('Er is een netwerkfout opgetreden. Probeer het later opnieuw.');
    }
  };

  return {
    email,
    setEmail,
    type,
    setType,
    language,
    setLanguage,
    toonPopup,
    sluitPopup,
    handleInschrijven,
    bevestigd,
    error,
  };
};

export default useNieuwsBrief;
