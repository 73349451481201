import React, { useState, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '/assets/vector/logo.svg';
import SubHeader from './SubHeader'; // Import the SubHeader component
import gsap from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

// import { useWishlist } from '../context/WishlistContext'; // import the useWishlist hook
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook

const Header = forwardRef((props, ref) => {
  const { openSearch, showSubHeader, subHeaderText } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);







  const { isAuthenticated, logout } = useAuth(); // Ophalen van `isAuthenticated` en `logout` direct uit context

  // const { wishlist } = useWishlist(); // get wishlist from the context

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.style.overflow = 'hidden'; // Lock body scrolling
    } else {
      document.body.style.overflow = 'auto'; // Restore body scrolling
    }
  };

  const handleScroll = () => {
    if (window.scrollY > lastScrollY && window.scrollY > 100) {
      setIsScrollingUp(false);
    } else {
      setIsScrollingUp(true);
    }

    if (window.scrollY > 200) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }

    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    const navLinks = document.querySelectorAll('.overlay-nav a');
    
    if (menuOpen) {
      // Animate the links from above with a delay
      gsap.fromTo(navLinks, 
        { y: -5, opacity: 0 }, 
        { 
          y: 0, 
          opacity: 1, 
          stagger: 0.05, 
          duration: 0.4, 
          ease: 'power1.out'
        }
      );
    } 
    // No animation on closing
}, [menuOpen]);

useEffect(() => {
  if (menuOpen) {
    document.querySelector('.header').classList.add('overlay-open');
  } else {
    document.querySelector('.header').classList.remove('overlay-open');
  }
}, [menuOpen]);


useEffect(() => {
  const header = document.querySelector('.header');

  if (menuOpen) {
    header.classList.remove('scrolled');
    document.body.style.overflow = 'hidden'; // Voorkom scrollen van de body
  } else {
    document.body.style.overflow = 'auto'; // Sta scrollen weer toe

    // Voeg de 'scrolled' class pas toe nadat het menu volledig gesloten is
    const timeoutId = setTimeout(() => {
      if (window.scrollY > 200) {
        header.classList.add('scrolled');
      }
    }, 300); // 300ms komt overeen met de sluitingstijd van de animatie (pas dit aan als je animatieduur anders is)
    
    return () => clearTimeout(timeoutId); // Schoonmaakfunctie voor de timeout
  }
}, [menuOpen]);

useEffect(() => {
  const handleScroll = () => {
    const header = document.querySelector('.header');

    // Alleen 'scrolled' class toevoegen als het menu niet open is
    if (!menuOpen && window.scrollY > 200) {
      header.classList.add('scrolled');
    } else if (window.scrollY <= 200) {
      header.classList.remove('scrolled');
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [menuOpen]);


  const getBreadcrumb = () => {
    // Only render the breadcrumb if the path starts with /collecties/exclusief
    if (location.pathname.startsWith('/collecties/exclusief')) {
      const pathnames = location.pathname.split('/').filter((x) => x);
      const subPath = pathnames.slice(2).join(' / '); // Get the part after /exclusief

      return (
        <div className="breadcrumb-exclusief-container">
          <div className="breadcrumb-container-inhoud">
            <div className="breadcrumb-exclusief">EXCLUSIEF</div>
            {subPath && (
              <div className="breadcrumb-subpath">{subPath}</div>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <header ref={ref} className={`header ${isScrollingUp ? 'visible' : 'hidden'} ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-content">
        <Link to="/" onClick={() => menuOpen && toggleMenu()}>
          <div className="logo">
            <img src={logo} alt="Thebalux Logo" />
          </div>
        </Link>

        <nav className={`nav-links ${menuOpen ? 'hidden' : ''}`}>
          <div className="dropdown">
            <Link to="/collecties" className="dropbtn">Collecties</Link>
            <div className="dropdown-content">
              <Link to="/collecties/exclusief">Exclusief</Link>
              <Link to="/collecties/beat40">Beat 40</Link>
              <Link to="/collecties/beat45">Beat 45</Link>
              <Link to="/collecties/nuance515">Nuance 51.5</Link>

              <Link to="/collecties/sjithouse">SjitHouse Toiletfurniture</Link>
              <Link to="/collecties/thor">Thor Badkamerkranen</Link>
              <Link to="/collecties/type">Type</Link>

              <Link to="/collecties/spiegels">Spiegels</Link>
              <a target='blank' href="https://spiegelopmaat.thebalux.nl">Spiegel op maat</a>

              <hr></hr>

              <Link to="/kleuren">Kleuren Overzicht</Link>
              <Link to="/downloads">Downloads</Link>

              {/* <Link to="/inspiratie">Inspiratie</Link> */}

            </div>
          </div>

          <Link to="/verkooppunten">Uw verkooppunt</Link>

          <div className="dropdown">
            <Link to="/service">Service</Link>
            <div className="dropdown-content">
              <Link to="/veelgestelde-vragen">Veelgestelde vragen</Link>
              <a href="https://service.thebalux.nl">Service melden</a>
              <Link to="/montage">Montage</Link>
            </div>
          </div>
          <Link to="/contact">Contact</Link>
        </nav>

        <div className="header-icons">

        <button className="search-icon" onClick={() => {
          console.log("Search button clicked"); // Voeg deze regel toe voor debugging
          openSearch(); // Roep openSearch direct aan vanuit de props
        }}>
          <FontAwesomeIcon icon={faSearch} />
        </button>


          {/* <Link to="/verlanglijstje" className="wishlist-icon">
            <FontAwesomeIcon icon={faHeart} />
            {wishlist.length > 0 && (
              <span className="wishlist-count">{wishlist.length}</span>
            )}
          </Link> */}

{/*          
          <Link to="/login" className="search-icon">
              <FontAwesomeIcon icon={faUser} />
            </Link> */}
         
          <div className={`burger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="burger-icon">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        </div>
      </div>

      

      {/* Conditionally Render Breadcrumb Section */}
      {getBreadcrumb() && (
        <div className={`breadcrumb-container ${isScrollingUp ? 'visible' : 'hidden'} ${isScrolled ? 'scrolled' : ''}`}>
          <div className="breadcrumb-container-inhoud">
            {getBreadcrumb()}
          </div>
        </div>
      )}

      <div className={`overlay ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
          <nav className="overlay-nav">
            <div className='BigLinks'>
              <Link to="/" onClick={toggleMenu}><h3>Home</h3></Link>
              <Link to="/collecties" onClick={toggleMenu}><h3>Collecties</h3></Link>
              <Link to="/verkooppunten" onClick={toggleMenu}><h3>Uw verkooppunt</h3></Link>
              <Link to="/showroom" onClick={toggleMenu}><h3>Showroom</h3></Link>
              {/* <Link to="/inspiratie" onClick={toggleMenu}><h3>Inspiratie</h3></Link> */}
              <Link to="/service" onClick={toggleMenu}><h3>Service</h3></Link>
            </div>

            <div className='SmallLinks'>
              <Link to="/kleuren" onClick={toggleMenu}>Kleuren</Link>
              <Link to="/veelgestelde-vragen" onClick={toggleMenu}>Veelgestelde vragen</Link>
              <Link to="/downloads" onClick={toggleMenu}>Downloads</Link>
              <Link to="/montage" onClick={toggleMenu}>Montage</Link>
              <Link to="/werken-bij" onClick={toggleMenu}>Vacatures</Link>
              <Link to="/over-ons" onClick={toggleMenu}>Over ons</Link>
              <Link to="/contact" onClick={toggleMenu}>Contact</Link>
            </div>
          </nav>

          <div className="overlay-image">
            <img src="/assets/img/main/beat45/20240305-Cube100NavarroAvia-CPC-CloseUpCabinet2.webp" alt="Slide 1" className="active" />
          </div>

          
        </div>

        <div className='bottom'>
              <img src='/assets/vector/Beeldmerk(chatback).svg' width='20px'/>

              <Link to="/privacy" onClick={toggleMenu}>Privacy</Link>
              <Link to="/duurzaamheid" onClick={toggleMenu}>Duurzaamheid</Link>
              <Link to="/werken-bij" onClick={toggleMenu}>Vacatures</Link>
              <a target='blank' href="https://imagebank.thebalux.nl" onClick={toggleMenu}>Imagebank</a>
          </div>


      </div>
    </header>
  );
});

export default Header;
