import React from 'react';
import './Note.css';

const Note = ({ title, sub}) => {
    return (
        <div className="note">
                <h1>{title}</h1>
                <p>{sub}</p>
        </div>
    );
}

export default Note;
